<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Feedback</h1>
      </div>
    </div>
    
    <div class="flex justify-center" >
      <div class=" sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class="card rounded-xl bg-white p-4 my-4">
          <TextAreaInput
              :inputId="'detailsforrquest'"
              :inputext="sendObj.notes"
              :placholderText="`Add your feedback here...`"
              :lableText="''"
              :inputType="'text'"
              :autoFocus="false"
              :textMaxlength="5000"
              :showcharLimit="true"
              :cols="50"
              :rows="4"
              :setReadOnly="false"
              @inputChangeAction="(data) => sendObj.notes = data"  />
        </div>
        <div class="flex justify-end  card rounded-xl bg-white p-4 my-4">
          <div class="text-rigth flex gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'"
              @buttonAction="discard()" />
            <Button :disabled="sendObj.notes === ''" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Send Feedback'"
              @buttonAction="deleteOrganiFinal()" />
          </div>
        </div>
      </div>
        
    </div>
  </div>
</template>
<script>
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
export default {
  name: "customer",
  components: {
    TextAreaInput,
    Button,
  },
  data() {
    return {
      sendObj: {
        appVersion: '',
        buildVersion: '',
        os: '',
        osVersion: '',
        deviceBrand: '',
        deviceModel: '',
        deviceUniqueId: '',
        deviceResolution: '',
        deviceConnectivityOn: '',
        deviceLocalTime: new Date(),
        internetSpeed: '',
        isLocationPermission: false,
        locationObj: '',
        isCameraPermission: false,
        isFilePermission: false,
        isContactsPermission: false,
        isNotificationPermission: false,
        isMicPermission: false,
        batteryLevel: '',
        notes: '',
        requesttype: 'web',
      }
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    discard (){
      this.$router.push({name: 'dashboard1'}).catch(()=>{});
    },
    deleteOrganiFinal() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.SendFeedbackData(
        this.sendObj,
        (response) => {
            document.body.style = 'overflow: visible;'
            this.$router.push({name: 'dashboard1'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            console.log('error', error);
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
  },
  beforeDestroy() {
  },
};
</script>
<style scoped>
</style>